import { useContext } from 'react';
import { RootStoreContext } from '../context/RootStoreContext';

export const useRootStore = () => {
    const context = useContext(RootStoreContext);

    if (context === undefined) {
        console.error('useRootStore must be used within RootStoreContext Provider')
    }

    return context
};

