import axios from "axios"
import { ConfigStore } from "./config.store"
import { UserStore } from "./user.store"
import { enableStaticRendering } from "mobx-react"
import { configure } from "mobx"

// Configures Mobx Store options
configure({
  enforceActions: "always",
})

const isServer = typeof window === "undefined"
enableStaticRendering(isServer)

let store = null

const baseURL = process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

export default function RootStore(
  initialData = { configStore: {}, userStore: null },
) {
  
  if (isServer) {
    return {
      configStore: new ConfigStore(initialData.configStore),
      userStore: new UserStore(initialData.userStore),
    };
  }

  if (store === null) {
    store = {
      configStore: new ConfigStore(initialData.configStore),
      userStore: new UserStore(initialData.userStore),
    };
  }

  return store
}
