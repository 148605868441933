import React, { useEffect, useState } from "react"
import { RootStoreContext } from "../context/RootStoreContext"
import RootStore from "../store/root.store.js"
import "./styles.css"
import dynamic from "next/dynamic"
import { Box, CircularProgress, Typography, Stack, styled } from "@mui/material"
import { JobsProvider } from "../context/JobsContext"
import { usePathname } from "next/navigation.js"
import axios from "axios"
import Starfield from "react-starfield"
import mixpanel from "mixpanel-browser"

const AuthenticatedLayout = dynamic(
  () => import("../components-clean/AuthenticatedLayout.jsx"),
  { ssr: false },
)
const MixPanelWrapper = dynamic(
  () => import("../components-clean/MixPanelWrapper.jsx"),
  { ssr: false },
)

const PWAHeader = dynamic(
  () => import("../components-clean/ui/PWAHeader.jsx"),
  {
    ssr: false,
  },
)

const StyledAwaitingStore = styled(Box)({
  color: "white",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  margin: "-8px",
  gap: "16px",
})

const CLIENT_ONLY_ENV = false
const CLIENT_ONLY_ENV_ROLE = "" // "" | "artist" | "reviewer" | "admin"

const baseURL = process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

const _App = ({ Component, pageProps }) => {
  const [store, setStore] = useState(null)
  const [loading, setLoading] = useState(true)

  const pathname = usePathname()
  useEffect(() => {
    // Mimic an HTTP call with a timeout
    const fetchData = async () => {
      const api = axios.create({
        baseURL,
        withCredentials: true,
      })

      let success = false
      while (!success) {
        try {
          console.log("----------")
          console.log("trying to get current user")
          console.log("----------")

          let data, userInsight, hasStripeAccount

          if (CLIENT_ONLY_ENV) {
            data = {}

            if (CLIENT_ONLY_ENV_ROLE) {
              data.currentUser = {
                accountType: CLIENT_ONLY_ENV_ROLE,
                username: "jbaxter6",
                email: "john@clutchaudience.com",
              }
              // data.currentUser = null
            } else {
              data.currentUser = null
            }

            userInsight = {}
            // userInsight =
            //   data.currentUser?.accountType === "reviewer"
            //     ? USER_INSIGHT_DEMO
            //     : {}
          } else {
            const { data: currentUserData } = await api.get(
              "/api/users/currentuser",
            )

            data = currentUserData

            if (data.currentUser) {
              if (data.currentUser.accountType === "reviewer") {
                const { data: userInsightData } = await api.get(
                  `/api/userInsights/user/${data.currentUser.id}`,
                )

                userInsight = userInsightData ? userInsightData : {}
              }

              mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)

              mixpanel.identify(data.currentUser.id)

              mixpanel.people.set({
                $first_name: data.currentUser.firstName,
                $last_name: data.currentUser.lastName,
                $email: data.currentUser.email,
                $created: new Date(),
                $last_login: new Date(),

                username: data.currentUser.username,
                accountType: data.currentUser.accountType,
                phoneNumber: data.currentUser.phoneNumber,
                provider: data.currentUser.provider,
                instagramUsername: data.currentUser.instagramUsername,
                twitchUsername: data.currentUser.twitchUsername,
                twitterUsername: data.currentUser.twitterUsername,
                youtubeUsername: data.currentUser.youtubeUsername,
              })
            } else {
              // Clear out Mixpanel user if there is no data.currentUser
              mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
              mixpanel.reset()
            }
          }

          const initialData = {
            userStore: { currentUser: data.currentUser || null },
            configStore: {
              CLIENT_ONLY_ENV: CLIENT_ONLY_ENV,
              darkMode: true,
              user: {
                loggedIn: !!data.currentUser,
                userInsight: userInsight,
                ...data.currentUser,
              },
            },
          }

          const _store = await new RootStore(initialData)

          setStore(_store)
          success = true
          setLoading(false)
        } catch (error) {
          console.error("Error fetching user data, retrying...", error)
          await new Promise(resolve => setTimeout(resolve, 2000)) // Wait for 2 seconds before retrying
        }
      }
    }

    const timer = setTimeout(() => {
      fetchData()
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  if (pathname === "/")
    return (
      <div style={{ width: "100vw", height: "100vh", margin: "-8px" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={"https://clutchaudience.io"}
        />
      </div>
    )

  if (!store || loading) {
    return (
      <StyledAwaitingStore>
        <Starfield
          starCount={10000}
          starColor={[255, 232, 31]}
          speedFactor={0.09}
          backgroundColor='black'
        />

        <Typography variant={"h6"} fontWeight={"bold"}></Typography>
        <Typography variant={"body1"} fontWeight={"bold"}>
          GIVE US A MOMENT{" "}
        </Typography>
      </StyledAwaitingStore>
    )
  }

  return (
    <RootStoreContext.Provider value={store}>
      <link
        href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Poppins:wght@300;500;700&family=Roboto:wght@400;500&display=swap'
        rel='stylesheet'
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <JobsProvider>
          <AuthenticatedLayout>
            <PWAHeader />
            <Component {...pageProps} />
          </AuthenticatedLayout>
        </JobsProvider>
      </Box>
    </RootStoreContext.Provider>
  )
}

export default _App

export const USER_INSIGHT_DEMO = {
  // Good
  dateOfBirth: "1995-03-07",
  state: "Virginia",
  zipcode: "22026",
  race: ["White"],
  gender: "Male",
  // kidsInHousehold: "",
  // exerciseLevel: "",
  // exerciseType: "",

  // Good
  employmentStatus: "Employed full-time",
  employmentJobTitle: "Car Designer",
  employmentIncome: "$5,000 to $9,999",

  // Good
  favoriteHobbies: ["Basketball", "Football"],
  favoriteMusicGenres: ["Country", "Rap", "Hip-Hop"],
  favoriteMusicArtists: ["Alton Ellis", "Drake", "J. Cole"],
  // favoriteSongs: "",

  // Good
  socialMediaPlatforms: ["Instagram", "YouTube", "X (Twitter)"],
  musicSubscriptions: ["Apple Music", "Spotify"],
  favoriteStreamingPlatforms: ["Hulu", "Disney+"],
  //  Good
  educationLevel: "Bachelor's degree",
  relationshipStatus: "Married",
  transportation: "own",
  housing: "Own a house",
  pets: "bird",
}
