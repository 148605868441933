import { makeAutoObservable } from 'mobx';

export class UserStore {
  currentUser = null;

  constructor(initialUser = null) {
    makeAutoObservable(this);
    this.currentUser = initialUser;
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  get isLoggedIn() {
    return this.currentUser !== null;
  }
}

export const userStore = new UserStore(); // This creates a singleton instance.
