import React, { createContext, useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import axios from "axios"

import { useRootStore } from "../hooks/useRootStore"
const JobsContext = createContext()

export const useJobs = () => useContext(JobsContext)

const BASE_URL = process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

export const JobsProvider = ({ children }) => {
  const { configStore } = useRootStore()
  const router = useRouter()

  const [jobs, setJobs] = useState([])
  const [completedJobId, setCompletedJobId] = useState("")

  const CLIENT_ONLY_ENV = configStore.store.CLIENT_ONLY_ENV

  /**
   * Fetch jobs and set jobs
   * */
  useEffect(() => {
    const fetchJobs = async () => {
      if (CLIENT_ONLY_ENV) {
        setJobs(TEST_JOBS)
      } else {
        try {
          const response = await axios.get("/api/jobs")
          // filter oout completed job
          if (completedJobId) {
            const filtered = response.data.filter(
              job => job._id !== completedJobId,
            )
            setJobs(filtered)
          } else {
            setJobs(response.data)
          }
        } catch (error) {
          console.error("Error fetching jobs: ", error)
        }
      }
    }

    if (
      configStore.store?.user &&
      configStore.store?.user?.accountType === "reviewer" &&
      router.pathname === "/marketplace"
    ) {
      fetchJobs()
    }
  }, [configStore.store.user, router.pathname])

  return (
    <JobsContext.Provider value={{ jobs, setJobs, setCompletedJobId }}>
      {children}
    </JobsContext.Provider>
  )
}

const TEST_JOBS = [
  {
    _id: "66ecfe14aa60f22f9f8002b8",
    title: "Your first Job Buddy",
    genres: ["Alternative", "Rap"],
    status: "created",
    pay: 1.25,
    quantity: 10,
    currentCount: 0,
    expiresAt: "2024-09-19T00:00:00.000Z",
    fileName: "file_1726807558469_SHORTSONG.mp3_",
    artId: "art-id-1726807562815-718",
    audSurveyId: "aud-survey-id-1726807562815-738",
    ticketId: "66ecfe148ea7e8fd8b0daa4b",
    ticket: {
      _id: "66ecfe148ea7e8fd8b0daa4b",
      projectId: "project-1726807562814-235",
      projectName: "projectname",
      songName: "ybujhknwyudfhjnw",
      sessionName: "dkjebqbduy",
      sessionGoal: "yjhgfbiuyewkdf",
      sessionDescription: "yhbfinehjgfbnweiu",
      duration: "18.834286",
      title: "dkjebqbduy-ybujhknwyudfhjnw",
      cost: 10,
      pay: 1,
      quantity: 10,
      startDate: "2024-09-19T00:00:00.000Z",
      dueDate: "2024-09-19T00:00:00.000Z",
      fileName: "file_1726807558469_SHORTSONG.mp3_",
      artId: "art-id-1726807562815-718",
      audSurveyId: "aud-survey-id-1726807562815-738",
      artSurveyId: "artist-survey-id-1726807562815-173",
      genres: ["uywhjbdnwd"],
      additionalNotes: "jhgnwbfuywhkjdh,n",
      emotions: ["ugwjhdgbwid"],
      message: "jghwgbdw",
      compArtists: ["byhwdw"],
      compSongs: ["wjghdbn"],
      userId: "66ecfd0ae8e782995c12c6c4",
      surveyType: "INTERVAL",
      songType: "snippet",
      targetState: ["Arizona"],
      targetInterests: ["Hiking"],
      targetAgeGroupStart: 23,
      targetAgeGroupEnd: 72,
      targetGender: ["Male"],
      igProfileLink: "",
      spotifySongLink: "",
      soundCloudSongLink: "",
      version: 0,
    },
    claims: [],
    reviews: [],
    version: 0,
  },
]
